import { Spin } from "antd";
import React from "react";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: any) => {
  return {
    spinner: {
      height: "-webkit-fill-available",
      textAlign: "center"
    }
  };
});

export const Loader = () => {
  const classes = useStyles();
  return (
    <div className={classes.spinner}>
      <Spin size="large" />
    </div>
  );
};
