import { SettingOutlined } from "@ant-design/icons";
import React from "react";
import { createUseStyles } from "react-jss";

import { SignIn } from "../auth";

const useStyles = createUseStyles((theme: any) => {
  return {
    flex: {
      marginTop: "50px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column"
    },
    settings: {
      color: theme.primary,
      fontSize: "100px"
    }
  };
});

interface IMaintenanceProps {
  maintenanceMode?: boolean;
}

const Maintenance = (props: IMaintenanceProps) => {
  const classes = useStyles();
  const { maintenanceMode } = props;

  return maintenanceMode === true ? (
    <div className={classes.flex}>
      <SettingOutlined className={classes.settings} />
      <div className={classes.flex}>
        <h1> We are currently in maintenance mode.</h1>
        <h2> Please come back later.</h2>
      </div>
    </div>
  ) : (
    <SignIn />
  );
};

export { Maintenance };
