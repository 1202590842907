import { Layout } from "antd";
import React from "react";

export const Footer = () => {
  return (
    <Layout.Footer style={{ textAlign: "center", bottom: 10, width: "100%" }}>
      <span role="img" aria-label="copyright">
        ©️
      </span>
      BonBon
    </Layout.Footer>
  );
};
