import { Table } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { Link } from "react-router-dom";
import { API, exceptionHandler } from "shared-components";

import { LoadErrorComponent, NoDataComponent } from "../../components";
import { ComponentWrapper } from "../layout";

const useStyles = createUseStyles((theme: any) => {
  return {
    tableWrapper: {
      padding: "24px",
      background: "white",
      height: "-webkit-fill-available",
      "& th": {
        fontWeight: "bold !important",
        borderBottom: `1px solid ${theme.gray4} !important`,
        color: theme.gray9
      },
      "& td": {
        color: theme.gray8,
        borderBottom: `1px solid ${theme.gray4} !important`,
        "& a": {
          color: theme.blue6
        }
      },
      "& .ant-pagination-item-link": {
        borderRadius: 35
      },
      "& li": {
        borderRadius: 35
      }
    },
    container: {
      margin: 20
    },
    loaderWrapper: {
      width: "100%",
      height: "80vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    }
  };
});

interface Team {
  key: string;
  id: string;
  organizationName: string;
  ownerName: string;
  email: string;
  invitationCode: string;
}

export const TeamList = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [teamlist, setTeamList] = useState([] as Team[]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true as boolean);

  useEffect(() => {
    getTeamLists();
  }, []);

  const getTeamLists = async () => {
    setLoading(true);
    try {
      const response = await API.get(`admin/organizations`);
      if (response && response.data) {
        setTeamList(
          response.data.map((data: any) => {
            return {
              key: data.id,
              id: data.id,
              organizationName: data.organization.name,
              ownerName: data.user.name,
              email: data.user.email,
              invitationCode: data.team.invitationCode
            };
          })
        );
      }
      setLoading(false);
    } catch (error) {
      exceptionHandler(error, t);
      setError(true);
      setLoading(false);
    }
  };

  const columns = [
    {
      title: t("Organization name"),
      dataIndex: "organizationName",
      key: "organizationName"
    },
    {
      title: t("Team code"),
      dataIndex: "invitationCode",
      key: "invitationCode"
    },

    {
      title: t("Owner name"),
      dataIndex: "ownerName",
      key: "ownerName"
    },

    {
      title: t("Action"),
      key: "action",
      render: (text: string, data: Team) => {
        return <Link to={`organization/edit/${data.id}`}>{t("Edit")}</Link>;
      }
    }
  ];
  return (
    <ComponentWrapper title="Organizations" add="/organization/add">
      <div className={classes.container}>
        {error ? (
          <LoadErrorComponent />
        ) : (
          <div className={classes.tableWrapper}>
            {!loading && teamlist.length === 0 ? (
              <NoDataComponent />
            ) : (
              <Table
                loading={loading}
                dataSource={teamlist}
                columns={columns}
              />
            )}
          </div>
        )}
      </div>
    </ComponentWrapper>
  );
};
