import { Table } from "antd";
import React from "react";
import { createUseStyles } from "react-jss";

interface Props {
  rowKey: string;
  columns: any;
  dataSource: any;
  className?: string;
  rowClassName?: string;
  borderTop?: boolean;
  loading?: boolean;
  pagination?: boolean;
  handleRow?: any;
  currentPage?: number;
  pageSize?: number;
  totalData?: number;
  handleSizeChange?: any;
  handlePaginationChange?: any;
  rowSelection?: any;
  onChange?: any;
  sticky?: boolean;
}

let useStyles = createUseStyles((theme: any) => {
  return {
    tableStyle: ({ borderTop }) => {
      return {
        fontFamily: theme.brandFont,
        "& .ant-table-thead": {
          "& th": {
            height: 44,
            background: theme.lightBg,
            borderTop: borderTop && `1px solid ${theme.colorGrey}`,
            borderBottom: `2px solid ${theme.gray5}`
          }
        },
        "& .ant-table-thead > tr > th": {
          color: theme.primary,
          fontSize: "16px",
          lineHeight: "19px",
          letterSpacing: "0px",
          padding: "13px 5px 7px",
          textAlign: "left"
        },
        "& .ant-table-tbody > tr > td": {
          height: 46,
          fontSize: "16px",
          lineHeight: "19px",
          letterSpacing: "0px",
          padding: "0px 10px",
          textAlign: "left",
          color: theme.gray7
        },
        "& .ant-table-pagination.ant-pagination": {
          margin: {
            top: 27,
            bottom: 0
          }
        },
        "& .ant-table-column-sorters": {
          padding: 0
        }
      };
    },
    rowStyle: {
      cursor: "pointer"
    }
  };
});

export const TableComponent = ({
  rowKey,
  columns,
  dataSource,
  className,
  rowClassName,
  borderTop,
  loading,
  handleRow,
  pagination,
  currentPage,
  pageSize,
  totalData,
  handleSizeChange,
  handlePaginationChange,
  rowSelection,
  onChange,
  sticky
}: Props) => {
  const classes = useStyles({ borderTop });

  return (
    <Table
      columns={columns}
      rowKey={rowKey}
      dataSource={dataSource}
      loading={loading}
      className={`${classes.tableStyle} ${className}`}
      rowClassName={`${classes.rowStyle} ${rowClassName}`}
      onRow={handleRow}
      pagination={
        pagination !== undefined &&
        pagination && {
          position: ["bottomCenter"],
          showSizeChanger: true,
          current: currentPage,
          pageSize: pageSize,
          total: totalData,
          onShowSizeChange: handleSizeChange,
          onChange: handlePaginationChange
        }
      }
      rowSelection={rowSelection}
      onChange={onChange}
      sticky={sticky}
    />
  );
};
