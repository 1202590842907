import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button as ButtonComponent, Layout } from "antd";
import Title from "antd/lib/typography/Title";
import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useHistory } from "react-router-dom";
import { Button } from "shared-components";

import { Footer } from "./Footer";

const { Header, Content } = Layout;

const useStyles = createUseStyles((theme: any) => {
  return {
    siteLayout: {
      background: theme.lightBg
    },
    siteLayoutBackground: {
      display: "flex",
      background: theme.lightBg,
      margin: "0 16px"
    },
    siteHeading: {
      lineHeight: "37px !important",
      margin: "0 !important"
    },
    siteBody: {
      background: theme.gray3,
      // minHeight: "80vh",
      minHeight: "unset",
      fontStyle: "normal"
    },
    backButton: {
      float: "left",
      border: "none",
      width: "fit-content",
      marginRight: 10
    },
    heading: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center"
    },
    titleWrapper: {
      display: "flex"
    },
    addBtnWrapper: {
      display: "flex",
      alignItems: "center"
    }
  };
});

interface Props {
  children: React.ReactNode;
  add?: string;
  title: string;
  back?: boolean;
}

export const ComponentWrapper: React.FC<Props> = props => {
  const history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Layout className={classes.siteLayout}>
      <Header className={classes.siteLayoutBackground} style={{ padding: 0 }}>
        <div className={classes.heading}>
          <div className={classes.titleWrapper}>
            {props.back && (
              <ButtonComponent
                icon={<ArrowLeftOutlined style={{ fontSize: "16px" }} />}
                onClick={() => {
                  history.goBack();
                }}
                className={classes.backButton}
              />
            )}
            <Title className={classes.siteHeading} level={3}>
              {t(props.title)}
            </Title>
          </div>
        </div>
        {props.add && (
          <div className={classes.addBtnWrapper}>
            <Button
              size="large"
              onClick={() => {
                props.add && history.push(props.add);
              }}
            >
              {t("Add")}
            </Button>
          </div>
        )}
      </Header>
      <Content className={classes.siteBody}>{props.children}</Content>
      <Footer />
    </Layout>
  );
};
