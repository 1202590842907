import { LoadingOutlined } from "@ant-design/icons";
import { Input } from "antd";
import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";

import { SearchIcon } from "../assets";

interface Props {
  placeholder: string;
  loading?: boolean;
  className?: string;
  onChange?: (name: string, value: any) => void;
  name?: string;
  ref?: React.MutableRefObject<undefined>;
  clear?: boolean;
  setClear?: React.Dispatch<React.SetStateAction<boolean>>;
  allowClear?: boolean;
  initialValue?: string;
}

const useStyles = createUseStyles((theme: any) => {
  return {
    search: {
      background: theme.background,
      borderRadius: "3px",
      padding: 0,
      color: theme.formLabelColor,
      border: "none",
      "& input": {
        background: theme.background,
        fontSize: "16px",
        // padding: "0px 0px !important",
        lineHeight: "32px"
      },
      "& span": {
        margin: {
          left: 5,
          right: 5
        }
      },
      "& .ant-input-clear-icon:last-child": {
        marginRight: 4,
        fontSize: 15
      }
    }
  };
});

const Search: React.FC<Props> = ({
  onChange,
  clear,
  setClear,
  name,
  loading,
  className,
  placeholder,
  allowClear,
  initialValue = ""
}) => {
  const classes = useStyles();

  const [value, setValue] = useState(initialValue as string);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setValue(event.target.value);
    if (clear && setClear) {
      setClear(false);
    }
    let searchFor = event.target.value;
    searchFor = searchFor.toLocaleLowerCase();
    if (onChange) {
      onChange(name || "", searchFor);
    }
  };

  useEffect(() => {
    if (clear) {
      setValue("");
    }
  }, [clear]);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const icon = loading ? <LoadingOutlined /> : <SearchIcon />;

  return (
    <Input
      type="string"
      value={value}
      onChange={handleChange}
      className={`${classes.search} ${className}`}
      prefix={icon}
      placeholder={placeholder}
      allowClear={allowClear}
      defaultValue={initialValue}
    />
  );
};

export { Search };
