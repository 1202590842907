import React from "react";
import { createUseStyles } from "react-jss";

let useStyles = createUseStyles((theme: any) => {
  return {
    container: {
      padding: 20,
      background: theme.bgColor,
      minHeight: "100vh",
      width: "100%",
      textAlign: ({ center }: Props) => {
        return center ? "center" : "left";
      }
    }
  };
});

interface Props {
  children?: any;
  center?: boolean;
}

const Container: React.FC<Props> = props => {
  const classes = useStyles(props);

  return <div className={classes.container}>{props.children}</div>;
};

export { Container };
