import { Button as ButtonComponent } from "antd";
import React from "react";
import { createUseStyles } from "react-jss";

type ButtonType = "link" | "primary"|"ghost" | "default";

export interface Props {
  type?: ButtonType;
  className?: any;
  disabled?: boolean;
  htmlType?: "submit" | "button" | "reset";
  loading?: boolean;
  size?: "small" | "middle" | "large";
  margin?: any;
  shape?: "circle" | "round";
  ghost?: boolean;
  icon?: any;
  prefix?: any;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  id?: string;
}

let useStyles = createUseStyles((theme: any) => {
  return {
    button: {
      position: "relative",
      borderRadius: 2,
      ...theme.button,
      margin: ({ margin }: Props) => {
        return margin;
      },
      background: ({ type }: Props) => {
        switch (type) {
          case "link":
            return theme.light;
          case "primary":
            return theme.blue6;
          case "ghost":
            return theme.greyButtonBackground ;
          default:
            return theme.textWhite;
        }
      },
      color: ({ type }: Props) => {
        switch (type) {
          case "link":
            return theme.textWhite;
          case "primary":
            return theme.textWhite;
          case "ghost":
            return theme.textDarkGrey;
          default:
            return "#595959";
        }
      },
      border: ({ type }: Props) => {
        switch (type) {
          case "default":
            return "1px solid #D9D9D9";
          default:
            return null;
        }
      },
      padding: ({ size }: Props) => {
        switch (size) {
          case "small":
            return "1px 8px 1px 8px";
          case "middle":
            return "5px 16px 5px 16px";
          case "large":
            return "8px 16px 8px 16px";
          default:
            return "8px 16px 8px 16px";
        }
      },
      fontSize: ({ size }: Props) => {
        switch (size) {
          case "small":
            return 10;
          case "middle":
            return 14;
          case "large":
            return 16;
          default:
            return 16;
        }
      },
      "&:hover ": {
        background: ({ type }: Props) => {
          switch (type) {
            case "link":
              return theme.light;
            case "primary":
              return theme.blue6;
            case "ghost":
              return theme.greyButtonBackground;
            default:
              return theme.textWhite;
          }
        },
        color: ({ type }: Props) => {
          switch (type) {
            case "link":
              return theme.textWhite;
            case "primary":
              return theme.textWhite;
              case "ghost":
            return theme.textDarkGrey;
            default:
              return "#595959";
          }
        }
      },
      "&:focus ": {
        background: ({ type }: Props) => {
          switch (type) {
            case "link":
              return theme.light;
            case "primary":
              return theme.blue6;
              case "ghost":
                return theme.greyButtonBackground;
            default:
              return theme.textWhite;
          }
        },
        color: ({ type }: Props) => {
          switch (type) {
            case "link":
              return theme.textWhite;
            case "primary":
              return theme.textWhite;
              case "ghost":
                return theme.textDarkGrey;
            default:
              return "#595959";
          }
        }
      },
      "&:active ": {
        background: ({ type }: Props) => {
          switch (type) {
            case "link":
              return theme.light;
            case "primary":
              return theme.blue6;
              case "ghost":
                return theme.greyButtonBackground;
            default:
              return theme.textWhite;
          }
        },
        color: ({ type }: Props) => {
          switch (type) {
            case "link":
              return theme.textWhite;
            case "primary":
              return theme.textWhite;
              case "ghost":
                return theme.textDarkGrey;
            default:
              return "#595959";
          }
        }
      }
    }
  };
});

const Button: React.FC<Props> = props => {
  let { type = "primary", onClick, margin, size, icon, id } = props;
  const classes = useStyles({ type, margin, size });

  return (
    <ButtonComponent
      id={id}
      className={`${classes.button} ${props.className}`}
      htmlType={props.htmlType || "button"}
      icon={icon}
      {...props}
      onClick={onClick}
    >
      {props.children}
    </ButtonComponent>
  );
};

export { Button };
