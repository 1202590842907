import { notification } from "antd";
import { TFunction } from "i18next";

type AlertType = "success" | "error" | "info";

const Alert = (title: string, type: AlertType, error?: any, t?: TFunction) => {
  const alertClass = {
    background:
      type === "error" ? "#FFF1F0" : type === "info" ? "#E6F7FF" : "#F6FFED",
    border:
      type === "error"
        ? "1px solid #FFA39E"
        : type === "info"
        ? "1px solid #91d5ff"
        : "1px solid #B7EB8F",
    borderRadius: "2px"
  };

  const duration = type === "success" ? 5 : 10;

  const message =
    error && error.response && error.response.data && error.response.data.error
      ? t
        ? t(error.response.data.error.message)
        : error.response.data.error.message
      : t
      ? t(`${error}`)
      : `${error}`;

  const alertTitle = t ? t(title) : title;

  notification.open({
    key: message,
    style: alertClass,
    message: alertTitle,
    description: message || error,
    duration: duration,
    top: 10
  });
};
export { Alert };
