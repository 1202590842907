import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import { PorousLogoColor } from "../assets";

const useStyles = createUseStyles((theme: any) => {
  return {
    body: {
      position: "fixed",
      width: "100%",
      height: "100%",
      display: "flex",
      zIndex: 1000000,
      flexDirection: "column",
      backgroundColor: "#F5F5F5",
      alignItems: "center",
      justifyContent: "center",
      overflow: "hidden",
      fontSize: 30
    },
    text: {
      marginTop: 50
    }
  };
});

interface Props {
  externalUser?: boolean;
}

const Forbidden = ({ externalUser }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.body}>
      <a title={t("Back to home")} href="/">
        <PorousLogoColor />
      </a>
      <p className={classes.text}>
        {t("You are not authorized to access this page.")}
      </p>
    </div>
  );
};

export { Forbidden };
