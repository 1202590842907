import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useHistory } from "react-router-dom";
import { Button } from "shared-components";

const useStyles = createUseStyles((theme: any) => {
  return {
    container: {
      height: "-webkit-fill-available",
      width: "100%",
      background: "white"
    },
    info: {
      display: "block",
      fontSize: "14px",
      lineHeight: "22px",
      color: "#595959",
      marginBottom: "24px"
    },
    button: {
      fontSize: "14px",
      lineHeight: "22px",
      padding: "5px 16px",
      background: theme.blue6,
      borderColor: theme.blue6
    }
  };
});

interface Props {
  message: string;
  redirectTo: string;
}

export const DoneMessage: React.FC<Props> = props => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const handleClick = () => {
    history.push(props.redirectTo);
  };
  return (
    <div className={classes.container}>
      <span className={classes.info}>{props.message}</span>
      <Button type="primary" className={classes.button} onClick={handleClick}>
        {t("Back to list")}
      </Button>
    </div>
  );
};
