import { Button, Col, Form, Input, message, Radio, Row } from "antd";
import { RadioChangeEvent } from "antd/lib/radio";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useParams } from "react-router";
import { API, ErrorMessages, exceptionHandler } from "shared-components";

import { DoneMessage } from "../../components";
import { ComponentWrapper } from "../layout";

const useStyles = createUseStyles((theme: any) => {
  return {
    formItem: {
      marginBottom: 24,
      "& label": {
        fontSize: "14px",
        lineHeight: "22px",
        color: theme.gray9,
        fontWeight: "normal",
        marginBottom: 2
      },

      "& .ant-form-item-explain": {
        color: theme.red6,
        marginTop: 2
      },
      "& input": {
        fontSize: "14px"
      }
    },
    fullWidth: {
      maxWidth: "100%",
      borderRadius: 2
    },
    formWrapper: {
      padding: 24,
      background: "white",
      height: "-webkit-fill-available"
    },
    button: {
      borderRadius: 35,
      background: theme.blue6,
      borderColor: theme.blue6
    },
    container: {
      margin: 20
    },
    updateContainer: {
      width: "100%",
      height: 676,
      margin: 20
    }
  };
});

export const TeamForm = (props: any) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const [form] = Form.useForm();

  const [state, setState] = useState({
    teamName: "",
    ownerName: "",
    email: "",
    password: "",
    externalTeam: false,
    organizationType: "",
    organizationName: "asdf"
  });
  const [done, setDone] = useState(false);
  const [loading, setLoading] = useState(false);
  const [readOnly, setReadOnly] = useState(true);

  useEffect(() => {
    if (id) {
      fetchData(id);
    }
  }, [id]);

  const fetchData = async (id: string) => {
    try {
      const response = await API.get(`admin/organizations/${id}`);
      if (response && response.data) {
        setState({
          ...state,
          ownerName: response.data.user.name,
          teamName: response.data.team.name,
          email: response.data.user.email,
          externalTeam: response.data.team.externalTeam,
          organizationName: response.data.organization.name,
          organizationType: response.data.organization.type
        });
        form.setFieldsValue({
          ownerName: response.data.user.name,
          teamName: response.data.team.name,
          email: response.data.user.email,
          externalTeam: response.data.team.externalTeam,
          organizationName: response.data.organization.name,
          organizationType: response.data.organization.type
        });
      }
    } catch (error) {
      exceptionHandler(error, t);
    }
  };

  const handleData = async () => {
    setLoading(true);
    try {
      const response = !id
        ? await API.post(`admin/organizations`, state)
        : await API.put(`admin/organizations/${id}`, state);
      if (response && response.data) {
        setLoading(false);
        setDone(true);
      }
    } catch (error) {
      if (error.code && ErrorMessages[error.code]) {
        message.error(t(ErrorMessages[error.code]));
        return;
      }
      exceptionHandler(error, t);
      setLoading(false);
    }
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement> | RadioChangeEvent
  ) => {
    const name = e.target.name;
    const value = e.target.value;
    if (name) {
      setState(s => {
        return { ...s, [name]: value };
      });
    }
  };

  if (done) {
    return (
      <ComponentWrapper
        back
        title={id ? "Edit Organization" : "Add Organization"}
      >
        <div className={classes.updateContainer}>
          <div className={classes.formWrapper}>
            <DoneMessage
              message={t(
                id
                  ? t("The organization has been updated.")
                  : t("The organization has been added.")
              )}
              redirectTo="/organizations"
            />
          </div>
        </div>
      </ComponentWrapper>
    );
  }

  return (
    <ComponentWrapper
      back
      title={id ? "Edit Organization" : "Add Organization"}
    >
      <div className={classes.container}>
        <div className={classes.formWrapper}>
          <Row>
            <Col lg={8}>
              <Form
                onFinish={handleData}
                layout="vertical"
                scrollToFirstError
                form={form}
              >
                <Form.Item
                  required={false}
                  name="organizationName"
                  label={t("Organization name")}
                  className={classes.formItem}
                  rules={[
                    {
                      required: true,
                      message: t("Required")
                    },
                    {
                      max: 60,
                      message: t("Max Length Reached")
                    }
                  ]}
                >
                  <Input
                    name="organizationName"
                    data-testid="organizationName"
                    className={classes.fullWidth}
                    onChange={handleChange}
                  />
                </Form.Item>

                <Form.Item
                  required={false}
                  name="ownerName"
                  label={t("Owner name")}
                  className={classes.formItem}
                  rules={[
                    {
                      required: true,
                      message: t("Required")
                    },
                    {
                      max: 60,
                      message: t("Max Length Reached")
                    }
                  ]}
                >
                  <Input
                    name="ownerName"
                    data-testid="ownerName"
                    className={classes.fullWidth}
                    onChange={handleChange}
                  />
                </Form.Item>
                <Form.Item
                  id="email"
                  required={false}
                  name="email"
                  label={t("Email")}
                  className={classes.formItem}
                  rules={[
                    {
                      required: true,
                      message: t("Required")
                    },
                    {
                      type: "email",
                      message: t("Not a valid email")
                    },
                    {
                      max: 60,
                      message: t("Max Length Reached")
                    }
                  ]}
                >
                  <Input
                    name="email"
                    data-testid="email"
                    className={classes.fullWidth}
                    onChange={handleChange}
                    onFocus={() => {
                      return setReadOnly(false);
                    }}
                    readOnly={readOnly}
                  />
                </Form.Item>
                <Form.Item
                  label={t("Password")}
                  name="password"
                  required={false}
                  className={classes.formItem}
                  rules={[
                    {
                      required: !id,
                      message: t("Required")
                    },
                    {
                      min: 8,
                      message: t("Must be at least 8 characters")
                    }
                  ]}
                >
                  <Input.Password
                    name="password"
                    type="password"
                    className={classes.fullWidth}
                    onChange={handleChange}
                    data-testid="password"
                    onFocus={() => {
                      return setReadOnly(false);
                    }}
                    readOnly={readOnly}
                  />
                </Form.Item>

                <Form.Item
                  id="organization Type"
                  required={false}
                  name="organizationType"
                  label={t("Organization Type")}
                  className={classes.formItem}
                  rules={[
                    {
                      required: true,
                      message: t("Required")
                    }
                  ]}
                >
                  <Radio.Group
                    name="organizationType"
                    onChange={handleChange}
                    data-testid="organization Type"
                  >
                    <Radio value={"hospital"}>{t("Hospital")}</Radio>
                    <Radio value={"pharmacy"}>{t("Pharmacy")}</Radio>
                  </Radio.Group>
                </Form.Item>

                <Form.Item
                  id="externalTeam"
                  required={false}
                  name="externalTeam"
                  label={t("External Team")}
                  className={classes.formItem}
                  rules={[
                    {
                      required: true,
                      message: t("Required")
                    }
                  ]}
                >
                  <Radio.Group
                    name="externalTeam"
                    onChange={handleChange}
                    data-testid="externalTeam"
                  >
                    <Radio value={true}>{t("Yes")}</Radio>
                    <Radio value={false}>{t("No")}</Radio>
                  </Radio.Group>
                </Form.Item>

                <Form.Item>
                  <Button
                    type="primary"
                    loading={loading}
                    className={classes.button}
                    htmlType="submit"
                    size="middle"
                    data-testid="submit"
                  >
                    {id ? t("Update") : t("Add")}
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </div>
      </div>
    </ComponentWrapper>
  );
};
