import { Layout } from "antd";
import React from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { ThemeProvider } from "react-jss";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import {
  AuthProvider,
  Forbidden,
  MAINTENANCE_MODE,
  NotFound,
  RECAPTCHA_SITE_KEY,
  theme
} from "shared-components";

import { Maintenance } from "./pages/maintenance";
import { Home, PrivateRoute, RestrictedRoute } from "./routes";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Layout style={{ height: "100vh" }}>
        <AuthProvider>
          <BrowserRouter>
            <Switch>
              <PrivateRoute exact path="/403" component={Forbidden} />
              <Route exact path="/404" component={NotFound} />
              <RestrictedRoute
                exact
                path="/sign-in"
                component={() => {
                  return (
                    <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_KEY}>
                      <Maintenance
                        maintenanceMode={
                          MAINTENANCE_MODE === "true" ? true : false
                        }
                      />
                    </GoogleReCaptchaProvider>
                  );
                }}
              />
              <PrivateRoute path="/" component={Home} />
            </Switch>
          </BrowserRouter>
        </AuthProvider>
      </Layout>
    </ThemeProvider>
  );
}

export default App;
