import { Input } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

// import { PdfContext } from "../../../PdfContext";

interface Props {
  value?: string;
  label?: string;
  error?: string | boolean | number;
  required?: boolean;
  onBlur?: any;
  name: string;
  placeholder?: string;
  rows?: number;
  onChange: (event: any) => any;
  inputClass?: any;
  disabled?: boolean;
  autoSize?: boolean;
  autoFocus?: boolean;
  maxLength?: number;
  defaultValue?: string;
  containerClass?: any;
  labelClassName?: string;
}

let useStyles = createUseStyles((theme: any) => {
  return {
    container: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      marginBottom: 16,
      marginTop: 8
    },
    text: {
      border: (pdfLoading: boolean) => {
        return pdfLoading
          ? `1px solid ${theme.borderColor}`
          : `1px solid ${theme.colorGrey}`;
      },
      background: (pdfLoading: boolean) => {
        return pdfLoading && theme.textWhite;
      },
      overflowWrap: (pdfLoading: boolean) => {
        return pdfLoading && "break-word";
      },
      padding: (pdfLoading: boolean) => {
        return pdfLoading && 9;
      },
      minHeight: (pdfLoading: boolean) => {
        return pdfLoading && 50;
      },
      lineHeight: "30px",
      borderRadius: 2,
      width: "100%",
      "&::-webkit-scrollbar": {
        width: 5
      },
      "&::-webkit-scrollbar-track": {
        background: theme.lightGrey
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#c1c1c1",
        borderRadius: "10px"
      }
    },
    label: {
      marginBottom: "0",
      fontSize: 12,
      color: theme.gray6,
      letterSpacing: "0px",
      fontWeight: 300,
      display: "flex",
      lineHeight: "14px",
      "& > :first-child": {
        padding: 0
      }
    },
    error: {
      marginTop: 0,
      marginBottom: 0,
      fontSize: 12,
      color: theme.error,
      fontWeight: 300,
      display: "flex",
      lineHeight: "20px"
    }
  };
});

export const TextArea = ({
  label,
  labelClassName,
  required,
  value,
  onBlur,
  name,
  placeholder,
  rows,
  onChange,
  inputClass,
  containerClass,
  disabled = false,
  autoSize,
  autoFocus,
  maxLength,
  defaultValue,
  error
}: Props) => {
  const pdfLoading = false;
  const classes = useStyles(pdfLoading);
  const { t } = useTranslation();

  const [maxLengthError, setMaxLengthError] = useState(false as boolean);

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = event.currentTarget;
    if (maxLength && value.length >= maxLength) {
      setMaxLengthError(true);
      setTimeout(() => {
        setMaxLengthError(false);
      }, 3000);
      return;
    }
    onChange(event);
  };

  if (pdfLoading) {
    return (
      <div className={classes.container}>
        {label && (
          <p className={classes.label}>
            {label} {required ? "*" : null}
          </p>
        )}
        <div className={`${classes.text} ${inputClass}`}>{value}</div>
      </div>
    );
  }

  return (
    <div className={`${classes.container} ${containerClass}`}>
      {label && (
        <p className={`${classes.label} ${labelClassName}`}>
          {label} {required ? "*" : null}
        </p>
      )}

      <Input.TextArea
        className={`${classes.text} ${inputClass}`}
        name={name}
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
        onBlur={onBlur}
        autoFocus={autoFocus}
        autoSize={autoSize}
        rows={rows || 1}
        disabled={disabled}
        defaultValue={defaultValue}
      />
      {maxLengthError && (
        <p className={classes.error}>{t("Maximum number of characters")}</p>
      )}
      {error && <p className={classes.error}>{t(`${error}`)}</p>}
    </div>
  );
};
