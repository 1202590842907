import "firebase/auth";

import axios from "axios";
import firebase from "firebase/app";

/**
 * axios instance
 */
let API = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: { "Content-Type": "application/json" }
});

// request header
API.interceptors.request.use(
  async config => {
    const user = firebase.auth().currentUser;
    let token = "";
    if (user) {
      const idTokenResult = await user.getIdTokenResult();
      token = idTokenResult.token;
    }
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

API.interceptors.response.use(response => {
  return response.data;
});

export { API };
