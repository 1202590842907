import { Layout } from "antd";
import React from "react";
import { createUseStyles } from "react-jss";
import { Redirect, Switch } from "react-router-dom";

import { Sidebar } from "../pages/layout";
import { TeamForm, TeamList } from "../pages/Team";
import { PrivateRoute } from "./PrivateRoute";

const useStyles = createUseStyles(() => {
  return {
    container: {
      height: "-webkit-fill-available",
      width: "100%",
      display: "flex",
      flexDirection: "row"
    }
  };
});

export const Home = () => {
  const classes = useStyles();
  return (
    <Layout className={classes.container}>
      <Sidebar />
      <Switch>
        <PrivateRoute path="/organization/edit/:id" component={TeamForm} />
        <PrivateRoute path="/organization/add" component={TeamForm} />
        <PrivateRoute path="/organization" component={TeamList} />
        <Redirect to="/organization" from="/" />
      </Switch>
    </Layout>
  );
};
