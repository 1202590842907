import { Divider, Dropdown } from "antd";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";

import { API } from "../../api";
import { AuthContext, firebase } from "../../config";
import { exceptionHandler } from "../../utils";
import { Alert, Avatar, Button, Typography } from "../atoms";

const useStyles = createUseStyles((theme: any) => {
  return {
    dropdownContent: {
      position: "absolute",
      backgroundColor: "white",
      boxShadow: "0px 0px 7px rgba(0, 0, 0, 0.180392)",
      borderRadius: "10px",
      zIndex: 1,
      top: "-13px",
      right: 0,
      minWidth: "270px"
    },
    left: {
      float: "left"
    },
    right: {
      float: "right",
      color: theme.textPrimary,
      fontSize: 12,
      lineHeight: "14px",
      border: `1px solid ${theme.formLabelColor}`,
      padding: "7px 24px",
      borderRadius: "26px"
    },
    username: {
      float: "left",
      fontFamily: "Helvetica Neue",
      padding: 10,
      margin: "auto",
      color: theme.textPrimary
    },
    title: {
      fontSize: "14px",
      lineHeight: "20px",
      fontFamily: "Noto Sans JP",
      margin: "20px 18px 0px 18px",
      color: theme.colorTextGrey,

      "&:hover": {
        color: theme.primary
      }
    },
    inline: {
      display: "flex",
      alignItems: "center",
      padding: "15px 20px"
    },
    changeTeam: {
      fontFamily: "Helvetica Neue",
      color: theme.formLabelColor,
      marginBottom: 15
    },
    divider: {
      margin: {
        top: 14,
        bottom: 14
      }
    },
    team: {
      fontFamily: "Noto Sans JP",
      cursor: "pointer",
      fontSize: 16,
      textAlign: "center",
      lineHeight: "24px",
      margin: {
        bottom: 19
      },
      "&:hover": {
        color: theme.primary
      }
    },
    pointer: {
      cursor: "pointer",
      float: "right"
    },
    noTeam: {
      marginTop: 35,
      marginBottom: 150,
      fontFamily: "HeiseiKakuGoStd",
      color: theme.textPrimary,
      fontWeight: "bold"
    },
    headerIcon: {
      height: 25,
      width: 25
    },
    termsAndPolicy: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginBottom: 14,
      "& a": {
        color: theme.colorTextGrey,
        textDecoration: "underline",
        "&:hover": {
          color: theme.primary
        }
      }
    }
  };
});

interface Props {}

const AccountMenu: React.FC<Props> = () => {
  const classes = useStyles();
  const {
    profile,
    setDefaultTeam,
    setUser,
    defaultTeam,
    setProfile
  } = useContext(AuthContext);
  const { t } = useTranslation();
  const history = useHistory();

  const handleSignOut = async () => {
    try {
      await firebase.auth().signOut();
      localStorage.clear();
      sessionStorage.clear();
      setUser(null);
      setProfile({});
      setDefaultTeam("");
      history.push("/");
    } catch (error) {
      exceptionHandler(error, t);
    }
  };

  const handleTeamChange = async (id: string) => {
    try {
      if (defaultTeam === id) {
        Alert("success", "success", t("The team is already selected."), t);
        return;
      }

      const response = await API.post(`members/change-teams`, {
        team_id: id
      });

      if (response.data) {
        history.push(`/cases`);
        setDefaultTeam(id);
        Alert("success", "success", t("Team Changed Successfully."), t);
      }
    } catch (error) {
      exceptionHandler(error, t);
    }
  };

  const menu = (
    <div className={classes.dropdownContent}>
      <div className={classes.inline}>
        <div className={classes.left}>
          {profile && (
            <Avatar
              size="40"
              color={profile.color || "black"}
              name={profile.name || t("Not Set")}
              photo={profile.ProfileImage || ""}
            />
          )}
        </div>

        <Typography
          className={classes.username}
          fontSize="16px"
          lineHeight="24px"
        >
          {(profile && profile.name) || t("Not Set")}
        </Typography>

        <Button
          onClick={handleSignOut}
          type="default"
          className={classes.right}
        >
          {t("Sign Out")}
        </Button>
      </div>

      <Link to="/account-settings" className={classes.title}>
        {t("Account Settings")}
      </Link>

      <Divider className={classes.divider} />

      <Typography
        fontSize="14px"
        lineHeight="20px"
        center
        className={classes.changeTeam}
      >
        {t("Change Team")}
      </Typography>

      {profile && profile.teams && profile.teams.length ? (
        profile.teams.map((team: any) => {
          return (
            <p
              key={team.ID}
              onClick={() => {
                return handleTeamChange(team.ID);
              }}
              className={classes.team}
              style={{
                color: defaultTeam === team.ID ? "#1A51CB" : "#2E2E2E"
              }}
            >
              {team?.organization.name} {team.name}
            </p>
          );
        })
      ) : (
        <Typography
          className={classes.noTeam}
          fontSize="12px"
          lineHeight="14px"
          center
        >
          {t("You are not part of a team.")}
        </Typography>
      )}

      <Divider className={classes.divider} />

      <div className={classes.termsAndPolicy}>
        <Link
          to={"//bon-bon.co.jp/porous-terms-and-conditions/"}
          target="_blank"
          rel="noopener noreferrer"
        >
          {t("Porous Terms")}
        </Link>
        <Link
          to={"//bon-bon.co.jp/privacy-policy/"}
          target="_blank"
          rel="noopener noreferrer"
        >
          {t("Privacy policy")}
        </Link>
      </div>
    </div>
  );
  return (
    <Dropdown overlay={menu} trigger={["click"]}>
      <div className={classes.pointer}>
        {profile && (
          <Avatar
            name={profile.name || t("Not Set")}
            photo={profile.ProfileImage}
            color={profile.color}
            className={classes.headerIcon}
            size="25"
          />
        )}
      </div>
    </Dropdown>
  );
};

export { AccountMenu };
